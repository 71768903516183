.FilterColumn{
    width: 320px;
}

.FilterButton{
    background-color: #794396;
    border-color: #794396;
    font-family: 'Inter';
    font-weight: 700;
}

Text {
    font-family: 'Inter';
}

.OpenStickerButton{
    background-color: #794396;
    border-color: #794396;
    font-family: 'Inter';
    margin-top: 20px;
    font-weight: 700;
    width: 120px;
    float: right;
    margin-right: 20px;
}

.ResetButton{
    background-color: #FFFFFF;
    font-family: 'Inter';
    color: gray;
    border-color: #FFFFFF;
    box-shadow: none;
    font-weight: 700;
    margin-left: 20px;
}

.ResetButton:hover{
    background-color: #FFFFFF;
}

.OpenOrderBtn{
    background-color: #FFFFFF;
    color: #794396;
    border-color: #794396;
    width: 25px;
    height: 25px;
    padding: 0px;
    margin-top: 15px;
}

.OpenOrderBtn:hover{
    background-color: #794396;
    color: #FFFFFF;
}

.TableField{
    font-size: smaller;
}

.DownloadCSVButton {
    background-color: #794396;
    border-color: #794396;
    font-family: 'Inter';
    margin-top: 20px;
    font-weight: 700;
    width: 120px;
    float: right;
    margin-right: 120px;
}

.DownloadCSVContainer{
    justify-content: end;
}

.DeleteButton{
    background-color: #794396;
    border-color: #794396;
    font-family: 'Inter';
    margin-top: 20px;
    font-weight: 700;
    float: right;
    margin-right: 20px;
}

.DateSwitch {
    margin-right: 20px;
    margin-top: 25px;
}

/* SearchPage.css */
.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin-top: 20px;
  }
  
  .pagination li {
    margin: 0 5px;
  }
  
  .pagination li a {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    color: #333;
    text-decoration: none;
  }
  
  .pagination li.active a {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }
  
  .pagination li.disabled a {
    color: #ccc;
    cursor: not-allowed;
  }
  .spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #86198f; /* Cor do spinner */
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }